<template>
  <div>
    <nav
      class="navbar top-fixed navbar-light nav-header"
      style="display: flex; align-content: center"
    >
      <div>
        <button class="navbar-toggler" @click="collapse = !collapse">
          <span class="nav-icon"></span>
        </button>
      </div>
      <a class="title-text pt-2" href="#">mobican</a>
      <div class="float-right d-flex align-items-center">
        <b-dropdown>
          <template #button-content>
            <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
            {{ intervalItem }}
          </template>
          <b-dropdown-group header="自動更新時間" class="small">
            <b-dropdown-item-button
              class="interval my-2"
              v-for="(option, idx) in interval"
              :key="idx"
              :item="option.value"
              @click="onChange(option.value, idx)"
            >
              {{ option.value }}
              <b-icon icon="check" class="icon-disable" aria-hidden="true"></b-icon>
            </b-dropdown-item-button>
          </b-dropdown-group>
        </b-dropdown>

        <button class="notification">
          <img class="noti-icon" :src="notiIcon" />
        </button>
        <img class="user-avatar" :src="avatarImg" />
        <button
          class="acc-manage-dropdown-btn"
          @click="showAccManage"
          :class="setBgImg()"
          id="acc-manage-dropdown-btn"
        ></button>
        <div v-if="openAccManage" class="account-menu" id="account-menu">
          <span class="dropdown-item font-weight-bold mt-0"
            >{{ employeeName }}({{ employeeNumber }})</span
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">アカウント管理</a>
          <a class="dropdown-item noti-setting" href="#">通知設定</a>
          <a class="dropdown-item noti-setting" href="password-setting">パスワード設定</a>
          <a class="dropdown-item" href="#" @click="onLogout">ログアウト</a>
        </div>
      </div>
    </nav>
    <div>
      <ul class="float-left menu not-expand" v-if="!collapse">
        <li class="menu-icon">
          <router-link :to="{ name: 'LocationManage' }" title="運行状況">
            <img v-if="!locationActive" :src="location" />
            <img v-else :src="locationActiveIcon" />
          </router-link>
        </li>
        <li class="menu-icon">
          <router-link :to="{ name: 'AttendanceManage' }" title="勤怠管理">
            <img v-if="!historyActive" :src="history" />
            <img v-else :src="historyActiveIcon" />
          </router-link>
        </li>
        <li class="menu-icon">
          <router-link :to="{ name: 'EmployeeDataManage' }" title="従業員データ">
            <img v-if="!accountActive" :src="account" />
            <img v-else :src="userAccountActive" />
          </router-link>
        </li>
        <li class="menu-icon">
          <router-link :to="{ name: 'DeliveryPlanManage' }" title="配送計画">
            <img v-if="!deliveryPlanActive" :src="delivery" />
            <img v-else :src="deliveryActive" />
          </router-link>
        </li>
      </ul>
      <ul class="float-left menu expand" v-else>
        <li class="menu-icon menu-expand">
          <router-link :to="{ name: 'LocationManage' }" v-if="!locationActive">
            <img :src="location" />
            <a class="menu-text">運行状況</a>
          </router-link>
          <router-link :to="{ name: 'LocationManage' }" v-else>
            <img :src="locationActiveIcon" />
            <a class="menu-text" style="color: #8bdff9">運行状況</a>
          </router-link>
        </li>
        <li class="menu-icon menu-expand">
          <router-link :to="{ name: 'AttendanceManage' }" v-if="!historyActive">
            <img :src="history" />
            <a class="menu-text">勤怠管理</a>
          </router-link>
          <router-link :to="{ name: 'AttendanceManage' }" v-else>
            <img :src="historyActiveIcon" />
            <a class="menu-text" style="color: #8bdff9">勤怠管理</a>
          </router-link>
        </li>
        <li class="menu-icon menu-expand">
          <router-link :to="{ name: 'EmployeeDataManage' }" v-if="!accountActive">
            <img :src="account" />
            <a class="menu-text">従業員データ</a>
          </router-link>
          <router-link :to="{ name: 'EmployeeDataManage' }" v-else>
            <img :src="userAccountActive" />
            <a class="menu-text" style="color: #8bdff9">従業員データ</a>
          </router-link>
        </li>
        <li class="menu-icon menu-expand">
          <router-link :to="{ name: 'DeliveryPlanManage' }" v-if="!deliveryPlanActive">
            <img :src="delivery" />
            <a class="menu-text">配送計画</a>
          </router-link>
          <router-link :to="{ name: 'DeliveryPlanManage' }" v-else>
            <img :src="deliveryActive" />
            <a class="menu-text" style="color: #8bdff9">配送計画</a>
          </router-link>
        </li>
      </ul>
      <div class="child child-collapse mr-0" v-if="!collapse">
        <router-view></router-view>
      </div>
      <div class="child child-expand mr-1" v-else>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import location from "../assets/location-icon.png";
import locationActiveIcon from "../assets/location-active-icon.png";
import history from "../assets/history-icon.png";
import historyActiveIcon from "../assets/history-active-icon.png";
import account from "../assets/acc-setting.png";
import userAccountActive from "../assets/user-account-active.png";
import delivery from "../assets/calendar-appointment-date-icon.png";
import deliveryActive from "../assets/calendar-appointment-date-active-icon.png";
import notiIcon from "../assets/noti-icon.png";
import userDefault from "../assets/user-default.png";
import router from "../router";
import { mapGetters } from "vuex";
import jsCookie from 'js-cookie';

export default {
  computed: {
    ...mapGetters({
      employeeName: "auth/employeeName",
      employeeNumber: "auth/employeeNumber",
    }),
  },

  data() {
    return {
      routerName: null,
      collapse: false,
      openAccManage: false,
      location,
      locationActiveIcon,
      history,
      historyActiveIcon,
      account,
      userAccountActive,
      delivery,
      deliveryActive,
      notiIcon,
      userDefault,
      locationActive: false,
      historyActive: false,
      accountActive: false,
      deliveryPlanActive: false,
      interval: [
        { key: 1, value: "2分" },
        { key: 2, value: "5分" },
        { key: 3, value: "10分" },
        { key: 4, value: "30分" },
        { key: 5, value: "60分" },
        { key: 6, value: "オフ" },
      ],

      isOpenSettingInterval: false,
      intervalItem: jsCookie.get("intervalItem") || "2分",
      avatarImg: null,
    };
  },
  mounted() {
    this.getUserAvatar();
    this.collapse = false;
    this.openAccManage = false;
    this.setActiveIcon();
    this.setTickIcon();
  },
  watch: {
    $route() {
      this.setActiveIcon();
    },
  },
  methods: {
    // set icon tick for selected interval value
    setTickIcon() {
      const index = this.interval.findIndex((x) => x.value === this.intervalItem);
      document.querySelectorAll(".bi-check")[index].classList.add("icon-show");
    },

    //get user avatar
    getUserAvatar() {
      if (!this.isEmpty(localStorage.avatarImg)) {
        this.avatarImg = "data:image/(png|jpg|jpeg);base64," + localStorage.avatarImg;
      } else {
        this.avatarImg = userDefault;
      }
    },

    //when select interval item
    onChange(value, idx) {
      this.intervalItem = value;
      this.isOpenSettingInterval = false;
      let iconList = document.querySelectorAll(".bi-check");
      iconList.forEach((e, index) => {
        if (index !== idx) {
          e.classList.remove("icon-show");
          e.classList.add("icon-disable");
        } else {
          e.classList.add("icon-show");
        }
      });
      var intervalTime;
      switch (value) {
        case "2分":
          intervalTime = 2;
          break;
        case "5分":
          intervalTime = 5;
          break;
        case "10分":
          intervalTime = 10;
          break;
        case "30分":
          intervalTime = 30;
          break;
        case "60分":
          intervalTime = 60;
          break;
        default:
          intervalTime = 0;
          break;
      }
      this.$store.dispatch("home/getIntervalTime", intervalTime);
      jsCookie.set("intervalItem", this.intervalItem);
      jsCookie.set("intervalTime", intervalTime);
    },

    //set the active icon when choose a new page
    setActiveIcon() {
      // when choose 運行状況 page
      if (window.location.pathname === "/") {
        this.locationActive = true;
        this.historyActive = false;
        this.accountActive = false;
        this.deliveryPlanActive = false;

        // when choose 勤怠管理 page
      } else if (window.location.pathname === "/attend-manage") {
        this.historyActive = true;
        this.locationActive = false;
        this.accountActive = false;
        this.deliveryPlanActive = false;

        // when choose 従業員データ page
      } else if (window.location.pathname === "/employee-data") {
        this.accountActive = true;
        this.locationActive = false;
        this.historyActive = false;
        this.deliveryPlanActive = false;

        // when choose 配送計画 page
      } else if (window.location.pathname === "/delivery-plan") {
        this.deliveryPlanActive = true;
        this.accountActive = false;
        this.locationActive = false;
        this.historyActive = false;
      }
    },

    setBgImg() {
      if (this.openAccManage) {
        return "bg-expand-btn";
      } else {
        return "bg-collapse-btn";
      }
    },
    async onLogout() {
      // Log out the user.
      await this.$store.dispatch("auth/logout");
      //return to login screen
      router.push({ name: "Login" });
    },

    // when click outside acc-manage div, close account management menu
    showAccManage() {
      this.openAccManage = !this.openAccManage;
      window.addEventListener("click", (e) => {
        if (
          e.target !== document.getElementById("account-menu") &&
          e.target !== document.getElementById("acc-manage-dropdown-btn")
        ) {
          this.openAccManage = false;
        }
      });
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
  },
};
</script>

<style scoped>
.link {
  background-color: transparent;
  border: none;
  padding: 0;
}
.nav-header {
  position: absolute;
  width: -webkit-fill-available;
  max-height: 100%;
  left: 0px;
  top: 0px;
  background: #8bdff9;
}

.title-text {
  position: absolute;
  font-weight: 700;
  font-size: 2vw;
  height: 100%;
  left: 57px;
  color: #ffffff;
}
.title-text:hover,
.menu-text:hover {
  text-decoration: none;
}
.nav-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 15px;
  top: 30px;
  border: none;
  background-image: url("../assets/menu-icon.png");
}
.navbar-toggler {
  border: none;
}
.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: auto;
  height: -webkit-fill-available;
  left: 0px;
  top: 76px;
  background: #f3fbfd;
}
.not-expand {
  width: 57px;
}
.expand {
  width: 232px;
}
.menu-icon {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  margin-top: 45px;
}
.menu-expand {
  width: 190px;
  height: 15px;
  left: 57px;
  top: 97px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
ul li a {
  color: #000000;
}
.menu-text {
  margin-left: 17px;
}
.noti-icon {
  width: 30px;
  height: 38px;
  left: 1276px;
  top: 19px;
}
.notification {
  margin-right: 24px;
  border: none;
  background: transparent;
}
.user-avatar {
  width: 59px;
  height: 60px;
  /* background: url("../assets/avt-user.png"); */
  border: none;
}
.account-menu {
  position: absolute;
  right: 11px;
  top: 77px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  z-index: 1;
}
.dropdown-item {
  margin-top: 13px;
}
.noti-setting {
  margin-bottom: 18px;
}
.acc-manage-dropdown-btn {
  margin-left: 13px;
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
}
.bg-expand-btn {
  background: url("../assets/acc-manage-expand.png");
}
.bg-collapse-btn {
  background: url("../assets/acc-manage-collapse.png");
}
.child {
  padding: 0;
  position: fixed;
  overflow: auto;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  left: 0px;
  top: 70px;
  padding-top: 13px;
}
.child-collapse {
  margin-left: 57px;
}
.child-expand {
  margin-left: 232px;
}
.dropdown-divider {
  border-top: 0.5px solid #b6b1b1;
}
.interval-menu {
  position: absolute;
  width: 123px;
  right: 180px;
  top: 77px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
}
.interval-setting {
  width: 115px;
  height: 39px;
  margin-right: 13px;

  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.interval {
  font-size: 14px;
}

.bi-arrow-clockwise {
  display: inline-block;
  overflow: visible;
  width: 30px;
  height: 31px;
  color: #8bdff9;
}
.bi-check {
  display: inline-block;
  overflow: visible;
  width: 21px;
  height: 21px;
  margin-left: 20px;
  color: #8bdff9;
}
.icon-disable {
  visibility: hidden;
}
.icon-show {
  visibility: visible;
}
.b-dropdown >>> .btn-secondary {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 115px;
  height: 39px;
  right: 30px;
  box-sizing: border-box;
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.b-dropdown >>> .btn-secondary:hover {
  color: none;
  background-color: none;
  border-color: none;
}
.dropdown-menu li header.dropdown-header {
  color: #000000;
}
</style>
